import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/CommerceClub/Manager/1.jpg';
import p2 from 'assests/Photos/Clubs/CommerceClub/Manager/2.jpg';
import p3 from 'assests/Photos/Clubs/CommerceClub/Manager/3.jpg';
import p4 from 'assests/Photos/Clubs/CommerceClub/Manager/4.jpg';
import p5 from 'assests/Photos/Clubs/CommerceClub/Manager/5.jpg';
import p6 from 'assests/Photos/Clubs/CommerceClub/Manager/6.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { SideBarAssoc2023 } from './components';

const CommerceAssociation2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        }, 
        
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                THE BEST MANAGER
                                </Typography>
                              
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 9 - 12 &  Date: 27 OCTOBER 2023
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                               “Leadership is the capacity to transform vision into reality.” – Warren G. Bennis
                                <br />
                            </Typography>
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Commerce Association of National Public School, Yeshwanthpur organized ‘THE BEST MANAGER’ on 27 October 2023 for the students of classes 9 to 12.  As the name suggests, the students were judged based on their marketing, public relations, and financial crisis management skills. The aim of the event was to see how an individual performs under stress to fit in this fast-moving business world.
                                <br/>
                                Participants who gave the most convincing PR (Public Relations) statement and did the best job at regaining the trust of the public were taken to the final round- ‘The Pressure Interview’. Pressure, as one can probably guess, was a central theme of this round. The activity highlighted the significance of decisiveness. You can use the fanciest computers to gather numbers, but in the end, you have to set a timetable and act. It was truly enriching and enjoyable for the students as they experienced the cut-throat competition that prevails in the corporate world.
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                "THE SURVIVAL OF THE FITTEST!"
                                <br />
                            </Typography>
                            </Box>
                            
                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 500}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                              <SideBarAssoc2023/>
                        </Box>
                      
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default CommerceAssociation2023;